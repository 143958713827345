.ourVision{

    .heading{
        font-weight: 600;
    }
    .visionPara{
        font-weight: 500;
    }
    .marginDiv{
        margin-left:96px;
      }
    @media screen and (max-width:991px) {
        .marginDiv{
          margin-left:48px;
        }
    }
    @media screen and (max-width:767px) {
        .marginDiv{
          margin-left:0px;
        }
    }
}