.compare{
    
    .buyButton{
        transition: 0.5s;
        border-radius: 10px;
        padding: 10px;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
    }

    .rentButton:hover{
        border: 1px solid black !important;
        background-color: white !important;
        color: black !important;
    }

    @media (max-width:991px) {
        
        .featureTag{
           display: none;
        }
    }
    .backgroundFormExtra{
        font-weight: 500;
        border-radius: 12px;
        min-height: 48px !important;
        height: 48px !important;
        padding-top: 1.2rem !important;
        padding-bottom: 6px !important;
    }
    .inputSize{
  
        max-width:360px !important;
    }
    @media (max-width:767px) {
        .inputSize {
            max-width:100% !important;
        }
    }
    .responsiveText{
        font-size: 30px;
        line-height: 36px;
        font-weight: 600;
    }
    @media (max-width:576px) {
        .removePaddingXs{
            padding:6px !important;
        }
        .minMaxXs{
            min-height: fit-content !important;
            max-height: fit-content !important;
        }
        .responsiveText{
            font-size: 18px ;
            line-height: 18px;
            font-weight: 600;
        }
    }
    .lazy-image {
        height: 220px;
        width: 100%;
        margin: auto;
        cursor: pointer;
    }
    
    @media (max-width: 768px) {
        .lazy-image {
            height: 150px; /* Adjusted height for tablets or smaller screens */
        }
    }
    
    @media (max-width: 480px) {
        .lazy-image {
            height: 120px; /* Adjusted height for mobile devices */
        }
    }
}