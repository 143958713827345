.Home {

    // margin-top: -20%;
    .title {
        color: #b3b1b1;
        transition: color 0.3s ease;
    }

    .title:hover {
        color: #4579F5;
    }


    .title.selected {
        color: #fff;
    }

    h1 {
        font-weight: 600;
    }

    .rentButton {
        transition: background-color 0.3s ease, color 0.3s ease;
    }

    .rentButton:hover {
        background-color: white !important;
        color: black !important;
    }

    .carousel-indicators button {
        border: none;

        background: transparent;

    }

    .brandValue {
        text-wrap: nowrap;
        overflow: hidden;
    }

    .brandName {
        color: #737373;
    }

    @media (max-width:575px) {

        // .brandName{
        //     // font-weight: bolder;
        //     font-size: 14px;
        //     font-weight: 400 !important;
        // }
        .values {
            justify-content: start !important;
        }

        .brandValue {
            // padding-left: 5%;
            font-size: 14px;
            font-weight: 400 !important;
            text-align: right;
            padding-right: 0px;

        }

        .scooterName {
            font-size: 18px !important;
        }
    }

    .product_description {
        padding: 0 48px;
    }

    .productButtons {
        padding: 0 36px;
    }
}

@media (max-width:899px) {
    .Home {
        // margin-top: -25%;
    }

}

@media (max-width:768px) {
    .Home {
        .product_description {
            padding: 0 4px;
        }

        .productButtons {
            padding: 0 0px;
        }
    }
}

@media (max-width:576px) {
    .Home {
        // margin-top: -30%;

    }
}

@media (max-width:400px) {
    .Home {
        // margin-top: -35%;
    }
}

.containerMain {
    margin-top: -112px;
}

.secondHeading {
    // margin-bottom: 48px;
}

// .vehicleShow {
//     margin-top: 48px;
// }

@media (max-width:576px) {
    .containerMain {
        margin-top: 0px !important;
    }
    // .secondHeading {
    //     margin-bottom: 110px;
    // }
    // .vehicleShow {
    //     margin-top: 96px!important;
    // }
}