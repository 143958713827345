.store {

.pagination .page-item {
    display: none !important;
}
.pagination .page-item:first-child,
.pagination .page-item:last-child {
    display: inline-block !important;
}


    .pagination {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }

    .title{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.5;
        max-height: 3em;
    }
    .description{
        display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.5;
      max-height: 3em;
      width: 15rem;
    }
    .backgroundForm{
        font-weight: 500;
        border-radius: 12px;
        min-height: 48px !important;
        height: 48px !important;
        // padding-top: 1.2rem !important;
        // padding-bottom: 6px !important;
    }
    .backgroundFormExtra{
        font-weight: 500;
        border-radius: 12px;
        min-height: 48px !important;
        height: 48px !important;
        padding-top: 1.2rem !important;
        padding-bottom: 6px !important;
        background-color: transparent !important;
    }
    
    .storeProducts {
        transition: background-color 0.3s ease;
        padding-left: 10px;
        padding-right: 10px;

        .cardProduct {
            // width: 20rem;

            .CartButton {
                transition: background-color 0.3s ease, color 0.3s ease;
            }

            .CartButton:hover {
                // background-color: black !important;
                // color: white !important;
            }
        }
    }

    .storeProducts :hover {
        // background-color: #d3d3d3;
        // border-radius: 0px;
    }

    .labelWidth {
        font-size: 12px;

    }

    .pageFilter {
        font-size: 12px;
    }

    @media only screen and (min-width: 1200px) {
        .labelWidth {
            // width: 250px !important;
            height: calc(2.5rem + 2px);
        }

        .pageFilter {
            width: 150px !important;
        }
    }

    @media (min-width:576px) and (max-width: 768px) {
        .cardProduct {
            // width: max-content !important;
        }
    }

    @media (max-width:576px) {
        .cardProduct {
            // margin: auto;
        }
    }

    .inputSize{
  
        max-width:200px !important;
    }
    @media (max-width:767px) {
        .inputSize {
            max-width:100% !important;
        }
    }
}


.form-select~label::after{
    background: transparent !important;
}
