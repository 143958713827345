.Buy {
    font-family: "Manrope", sans-serif !important;
    // padding-bottom: 50px;

    .attributes {
        background-color: #1D1D1D0A;
        border-radius: 28px;
        padding: 9px 16px 9px 9.67px;
    }

    .carouselBuy {
        // height: 478px;
        position: relative;

        .carousel-indicators {
            bottom: -50px;
            /* Adjust position as needed */
        }

        /* Style for individual indicator buttons */
        .carousel-indicators button {
            border: none;
            /* Remove default border */
            background: transparent;
            /* Make background transparent */
        }

        /* Style for the images inside indicators */
        .carousel-indicators img {
            width: 30px;
            /* Adjust size as needed */
            height: 30px;
            /* Adjust size as needed */
            border-radius: 50%;
            /* Optional: makes indicators circular */
            border: 2px solid black;
            /* Border around the indicators */
            opacity: 0.7;
            /* Opacity for inactive indicators */
        }

        /* Style for the active indicator */
        .carousel-indicators .active img {
            border: 2px solid red;
            /* Border color for active indicator */
            opacity: 1;
            /* Fully opaque for the active indicator */
        }
    }

    .CartButton {
        transition: background-color 0.3s ease, color 0.3s ease;
        border-radius: 12px;
        padding:11px 41px;
    }

    .CartButton:hover {
        background-color: black !important;
        color: white !important;
    }
    
    .rentButton{
        transition: background-color 0.3s ease, color 0.3s ease;
        border-radius: 12px;
        padding:11px 41px;
    }
    .rentButton:hover {
        background-color: white !important;
        color: black !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .attributeText {
        font-size: 12px;
    }
 }
@media screen and (max-width: 500px) {
    .CartButton {
        padding:6px 10px !important;
    }
    
    .rentButton{
        padding:6px 10px !important;
    }
 }
 