.orderDetail{

    font-family: "Manrope", sans-serif !important;

    .orderDetailHeading{
        font-weight: 600;
        font-size: 36px;
    }
    .delivery{
        font-weight: 600;
        font-size: 18px;
        color: #1A1B1F;
    }
    .deliveryDate{

        color: #535353;
        font-weight: 500;
    }
    .order{
        font-weight: 400;
        color: #535353;
        font-size: 18px;
        opacity: 80%;
    }
    .orderDate{
        color:#1A1B1F;
        font-weight: 500;
    }
    @media  (max-width:992px) {
        .delivery{
            font-size: 14px;
        }
        .deliveryDate{
            font-size: 14px;
        }
        .order{
            font-size: 14px;
        }
        .orderDate{
            font-size: 14px;
        }
        .invoice{
            font-size: 14px;
        }

    }
    .lazy-load-image-background{
        display: flex !important;
        align-items: center;
        background: #EDEDED;
        padding:8.2px;
        border-radius: 12px;
    }
}