.metro{
    background-image: url('../../assets/images/heroImageMetro.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    // // top: -11rem;
    width: 100%;
    height: 80vh;
    
    max-height: 812px;
    z-index: -1;
    // padding: 220px 0px;
   

   
}
@media screen and (max-width:991px) {
    .hiddenDiv{
        min-height: 70vh;
    }
}
@media screen and (max-width:576px) {
    .blurDiv{
        // margin-top: 60px !important;

    }
}