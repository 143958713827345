.checkout{

    p {
        // font-weight: 600;
    }
   
    .rentButton{
        transition: background-color 0.3s ease, color 0.3s ease;
    }
    .rentButton:hover {
        background-color: white !important;
        color: black !important;
    }
    .CartButton {
        transition: background-color 0.3s ease, color 0.3s ease;
    }

    .CartButton:hover {
        background-color: black !important;
        color: white !important;
    }
    .form-floating>.form-select{
        height: 54px;
    }
    .PhoneInputCountryIconImg{
        // display: none;
    }
    .PhoneInputCountry{
        // visibility: hidden;
        // height: 0px;
        // color: black !important;
    }
    .PhoneInputInput{
        color: black !important;
    }

    
        .special-label{
            display: none !important;
        }
        // .react-tel-input {
        //     padding: 0 !important;
        //     border: 0!important;
        // }
        // .PhoneInput{
        //     padding: 0;
        // }
        // input[placeholder="Phone No"] {
        //     padding: 6px 12px;
        //     width: 100%;
        //     // padding: 11px 14px !important;
        //     border-radius: 8px !important;
        // }
        .special-label{
            display: none !important;
        }
        .phone-input {
            padding: 0 10px !important;
            border: 1px solid #dfdfdf !important;
            border-radius: 12px !important;
        }
        input[placeholder="Phone No"] {
                  padding: 6px 12px;
            width: 100%;
            border-radius: 8px !important;
            background: transparent;
            outline: none;
        }
   
}