.ProductDetails{
    
    .special-label{
        display: none !important;
    }
    .phone-input {
        padding: 0 10px !important;
        border: 1px solid #dfdfdf !important;
        border-radius: 12px !important;
    }
    input[placeholder="Phone No"] {
        padding: 11px 14px !important;
        border-radius: 12px !important;
        background: transparent;
        outline: none;
    }
}