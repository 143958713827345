.footer {
    font-family: "Manrope", sans-serif  !important;
    .footerWidgets {
        .footerLinks {
            display: flex;
            flex-direction: column;

            .links{
                color:rgba(255, 255, 255, 0.6);
                font-size: 14px;
            }
        }
        .newsletter {

            input {
                border: 1px solid #FFFFFF1F;
                border-radius: 16px;
                width: 100%;
                background: transparent;
                color: rgba(255, 255, 255, 0.6);
                padding: 13px;
            }
            .email{
                position: absolute;
                top: 0;
                right: 0;
                padding: 13px;
                border-radius: 16px;
            }
        }
        .copy_right{
           color:  rgba(255, 255, 255, 0.6);
           font-size: 14px;
        }
        
    }
    @media screen and (max-width:"768px") {
        
        .headingFooter{
            margin-top: 20px !important;
        }
    }
}