.perfectScooter {
   
    .backgroundForm{
        background-color: #ECF1F4;
        min-height: 48px !important;
        height: 48px !important;
       border-radius: 12px;
    }
    .backgroundFormOption{
        background-color: #ECF1F4;
        min-height: 48px !important;
        height: 48px !important;
        padding-top: 18px !important;
        padding-bottom: 5px !important;
        font-size: 14px ;
        // padding: 8px 16px;
    }
    .backgroundForm::placeholder {
        font-size: 14px;
        line-height: 24px;
        font-weight: 500px;
    }
    .orderButton{
        font-weight: 600;
        font-size: 16px;
        padding: 11px;
        border-radius: 12px;
        line-height:24px;
    }

    .orderButton:hover {
        background-color: #ECF1F4;
        color: #333;
        border-color: #333;
    }
    .payButton{
        background-color: #ECF1F4;
        border: 1px solid black;
        border-radius: 12px;
        font-weight: 600;
        font-size: 16px;
        padding: 10px;
        color: black;
    }
    .payButton:hover{
        color:white;
        background-color:black !important;
    }
    .label {
        background-color: #ECF1F4 !important;
        font-size: 12px;
        color: #000000;
        
    }
    .label ::after{
        background-color: #ECF1F4 !important;
        color: #000000;
        font-size: 12px;
    }

   
    @media (min-width:768px) {
        
        
        .max280{
           max-width: 280px;
            // margin-top: -100px;
        }
    }
    .scooterImage:hover{
         transform: scale(1.05);
    }
    .special-label{
        display: none !important;
    }
    .react-tel-input {
        padding: 0 !important;
        border: 0!important;
        background: transparent !important;
    }
    input[placeholder="Phone No"] {
        padding: 11px 16px !important;
        border-radius: 12px !important;
        background: transparent !important;
        font-size: 14px;
    }
}
