.orderList{
   padding-top: 0px !important;
    .orderHeading{
        font-size: 36px;
        font-weight: 600;
    }
    .items{
        // box-shadow: 5px 5px 10px #EAEAEA;
        border-radius: 12px;
        // height: 170px;
        border: 1px solid #EAEAEA;
        background-color:#FFFFFF;

        .deliveryDate{
            background-color:#EDEDED ;
            border-radius: 12px 12px 0px 0px ;
            .date{
                font-weight: 500;
                color: #535353
            }
        }
        
        .orderName{
            font-size: 20px;
            font-weight: 700;
            color: #1A1B1F;
        }
        .price{
            color: #535353;
            font-weight: 400;
            .priceValue{
                font-weight: 600;
            }
            .quantityValue{
                color: #1A1B1F;
                font-weight: 600;
            }
        }
        .invoice{
            color: #1C1B1F;
            font-weight: 500;
        }
    }
    .lazy-load-image-background{
        display: flex !important;
        align-items: center;
        background: #EDEDED;
        padding:8.2px;
        border-radius: 12px;

    }
}

@media (max-width: 576px) {
    .orderImage {
        // height: 85px;
        // width: 80px;
    }
}