.SignIn {

    font-family: "Manrope", sans-serif  !important;

    .form_box{
        
        .form-control {
            padding: 12.5px !important;
            border: 1px solid #1D1D1D1F !important;
            font-size: 14px !important;
            border-radius: 10px !important;
        }
        .passwordIcon{
            position: absolute;
            top:10px;
            right: 10px;
        }
        
    }
    
}