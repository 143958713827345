.navbar {

    font-family: "Manrope", sans-serif !important;
    background-color: transparent;
    padding:0 !important;


    .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4 {
        background-color: none !important;
    }

    .MuiButtonBase-root {
        color: black !important;
    }

    .MuiTypography-root {
        // padding-top: 20px;
        margin-left: 8px;
    }

    .navItems:hover {
        color: #0d6efd !important;
        background: none !important;
    }

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionFixed.mui-fixed {
        position: absolute !important;
        top: 0;
        width: 100%;
    }

    .carticon {
        background: rgb(29, 29, 29);
        color: white;
        border-radius: 40%;
        font-size: 12px;
        padding: 2px 8px;
        top: 9px;
    }


    // order now start 
    .orderNowBurron{
        background: #000000;
        color: #ffffff;
        padding: 10px 23.54px;
        border-radius: 40px;
        height: 48px;
        display: flex;
        align-items: center;
    }
}


.MuiPaper-elevation {
    // overflow: hidden !important;
}
.MuiPaper-elevation {
    // max-height: 80vh;
    // overflow-y: scroll;
    // overflow-x: hidden;
    // padding-bottom: 60px;
     /* Hide scrollbar for Firefox */
scrollbar-width: none; /* Firefox */

/* Hide scrollbar for Internet Explorer and Edge */
-ms-overflow-style: none; /* IE 10+ and Edge */

/* Hide scrollbar for WebKit browsers (Chrome, Safari) */
}

@media screen and (max-height:700px) {
    .MuiPaper-elevation {
        overflow:auto !important;
    }
}

@media (min-width:900px) and (max-width:1100px) {
    .navbar {

        .navItems {
            // font-size: 10px;
            padding-top: 20px;
        }

        .navIcon {
            height: 25px;
            padding-top: 10px;
            margin: 0px;
        }
       
    }
}
.carticonmobile{
    background: rgb(29, 29, 29);
    color: white;
    border-radius: 40%;
    font-size: 12px;
    padding: 1px 6px;
    right: 6px;
    top: -2px;
    // min-height: 22px;
    // min-width: 22px;
}

.MuiAppBar-root{
    padding: 0 32px !important;
}
.navBarCustom{
    padding:24px !important;
}
.highlight {
    background-color: red !important;
  }

@media screen and (max-width:576px) {
    .navbar{
        height:46px !important;
    }
    .navBarCustom{
        padding:0 10px !important;
    }
    .customLogo{
        height: 22px !important;
    }
}
