.cart {

    .fontCart {
        font-weight: 500;
    }

    .buttons:hover {
        background-color: white !important;
        color: black !important;
    }

    .boldpara {
        font-weight: 600;
    }

    .filledCart {
        .product_cart_box {
            background-color: #FAFAFA;
            border-radius: 12px;
        }

        .buy {
            font-size: 12px;
            background-color: #000;
        }

    }

    .add_cart_box {
        background-color: #FAFAFA;
        border-radius: 12px;
        color: #3a5fed;

        .addMore {
            font-size: 16px;
            margin-bottom: 0px;
            cursor: pointer
        }
    }
    // @media screen and (max-width:767px) {

        .flowScroll2 {
            max-height: 80vh;
            // overflow-y: scroll;
            // overflow-x: hidden;
            padding-bottom: 60px;
             /* Hide scrollbar for Firefox */
    scrollbar-width: none; /* Firefox */
    
    /* Hide scrollbar for Internet Explorer and Edge */
    -ms-overflow-style: none; /* IE 10+ and Edge */

    /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
        }
    // }
    .flowScroll{
        margin:0 40px;
    }
}

.rentButton {
    transition: background-color 0.3s ease, color 0.3s ease;
}

.rentButton:hover {
    background-color: white !important;
    color: black !important;
}

.cartFooter {
    position: absolute;
    bottom: 0;
}

@media screen and (max-height:'700px') {
    .cartFooter {
        position: unset;
    }
}
@media screen and (max-width:576px) {
    .flowScroll{
        margin:0 10px !important;
    }
}

